@media (max-width: 600px){
    aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-below{
        position: fixed !important;
        z-index: 15 !important;
    }
}


.menu-backup{
    height: 100vh;
    flex: 0 0 200px;
    max-width: 200px;
    min-width: 200px;
    position: fixed;
    width: 200px;
}