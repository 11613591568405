$theme-colors: (
    // "primary": #fc4366,
    "primary": #0A192F,
    "danger": #ff4136,
    "success": #64FFDA,
    "dark": #0A192F,
    "info": #172a45
);
@import "../node_modules/bootstrap/scss/bootstrap.scss";
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


li.ant-menu-item-selected{
  a{
    color: #0a192f !important
  }
  span{
    color: #0a192f !important

  }
}

h1.ck-placeholder{
  display: none;
}