.login-container {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
}

form {
    padding-top: 10px;
    font-size: 14px;
    margin-top: 30px;
}

.card-title {
    font-weight: 300;
}

.login-form {
    width: 330px;
    margin: 20px;
}

.sign-up {
    text-align: center;
    padding: 20px 0 0;
}

.alert {
    margin-bottom: -30px;
    font-size: 13px;
    margin-top: 20px;
}
.editor-sidebar {
    background-color: #e9ecef;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 18px;
    .row {
        margin-right: 0;
        margin-left: 0;
    }
}
